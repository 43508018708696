// routes only for guests (not connected) accounts
const guestOnlyRoutes = ['login', 'admin', 'confirm']
// routes only accessible through "accounts" subdomain
const accountsOnlyRoutes = [
  'logout',
  'first-run',
  'grant-provider-scope' /*, 'grant-provider-callback'*/,
]
// routes accessibles whatever the user is connected or not
const publicRoutes = [
  'qr-guests-qr',
  'grant-provider-callback',
  'meeting_rooms-dashboards-id',
]
// all other routes are private and require the user to be connected

const staticSubdomains = ['accounts', 'my']

export default defineNuxtRouteMiddleware((to, from) => {
  const { user, space } = useAuth()
  // get queried subdomain
  const subdomain = useSubdomain().value
  const requestedURL = useRequestURL()
  const redirectToParam = requestedURL.searchParams.get('redirect_to')
  requestedURL.searchParams.delete('redirect_to') // conserve query params except redirect_to

  const loginURL = new URL(useAccountsUrl().value)
  loginURL.pathname = '/login'
  loginURL.search = ''
  loginURL.searchParams.set('redirect_to', requestedURL.href)

  // If no route is matched, return
  if (to.matched.length === 0) {
    return
  }
  const routeName = to.name as string

  // User is not logged in
  if (!toValue(user).isLoggedIn) {
    // Private route & user is not logged in, redirect to login
    if (
      !(guestOnlyRoutes.includes(routeName) || publicRoutes.includes(routeName))
    ) {
      return navigateTo(loginURL.href, { external: true })
    }
  }
  // User is logged in
  else {
    // Guest only route & user is logged in, redirect to home
    if (guestOnlyRoutes.includes(routeName)) {
      if (redirectToParam && redirectToParam.includes('/direct/')) {
        return navigateTo(redirectToParam, { external: true })
      } else {
        return navigateTo(useMyComeenUrl().value, { external: true })
      }
    }

    // Wrong subdomain, return 403 error
    if (
      !user.value.isAdmin &&
      !staticSubdomains.includes(subdomain) &&
      space.subdomain !== subdomain
    ) {
      return createError({
        statusCode: 403,
        message: 'You do not have access to this space',
        fatal: true,
      })
    }
  }

  return
})
