import posthog from 'posthog-js'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  if (runtimeConfig.public.postHogKey) {
    const posthogClient = posthog.init(runtimeConfig.public.postHogKey, {
      api_host: runtimeConfig.public.postHogHost,
      capture_pageview: false,
      autocapture: false,
      loaded: () => {
        const auth = useAuth()
        if (auth.user.value?.id) {
          posthog.identify('' + auth.user.value.id)
          posthog.reloadFeatureFlags()
        }
      },
    })

    posthog.onFeatureFlags((flags) => {
      console.log(flags)
    })

    // Make sure that pageviews are captured with each route change
    const router = useRouter()
    router?.afterEach((to) => {
      nextTick(() => {
        if (
          !(
            to.fullPath.startsWith('/meeting_rooms/dashboards/') &&
            to.fullPath !== '/meeting_rooms/dashboards/new'
          )
        ) {
          posthog.capture('$pageview', {
            current_url: to.fullPath,
          })
        }
      })
    })

    return {
      provide: {
        posthog: posthogClient,
      },
    }
  }
})
